.registration-success {
  text-align: left;
  p {
    line-height: 1.4;
  }

  h4,
  h2 {
    font-weight: 700;
  }

  .text-container {
    margin-top: 30px;
  }
}
.text-hover {
  color: #fff;
  transition: 0.3s all ease-in-out;
  &:hover {
    color: #042141;
  }
}
