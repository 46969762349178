.print-invoice {
  font-family: monospace;
  font-size: 13px;
  .print-bold {
    font-size: 15px;
    font-weight: 800;
  }
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
    margin-top: 10px;
    margin-left: 20px;
  }
}
