body {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  transition: 0.3s all ease-in-out;
}
h1 {
  font-size: 45px;
  font-weight: 600;
  margin: 0;
}
p {
  margin-bottom: 0;
}
.medium-text {
  font-size: 20px;
}
.medium-bold-text {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0;
}
.big-regular-text {
  font-size: 30px;
  font-weight: 400;
}
.big-bold-text {
  font-size: 30px;
  font-weight: 600;
}
.small-text {
  font-size: 16px;
}
.extra-small-text {
  font-size: 12px;
}

p {
  margin: initial;
}
