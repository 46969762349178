// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// ----------------------------------------------------------------------------
header {
  padding: 10px 0px;
  background-color: white;
  position: relative;
  .icon-container {
    display: flex;
    height: 100%;
    align-items: center;
    column-gap: 15px;
    .image-container {
      width: 50px;
      img {
        width: 50px;
        height: 50px;
      }
    }
    .logo-text-container {
      h3 {
        font-size: 20px;
        font-weight: 700;
        margin: initial;
        color: #2f3c92;
      }
    }
  }
  .header-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    form {
      margin-right: 10px;
      width: 300px;
      @media (max-width: 600px) {
        display: none;
      }
      .input-group-rounded {
        height: 100%;
        border-color: transparent;
        .input-group-prepend {
          border-radius: 100%;
          border-right: none;
          border-color: transparent;

          .input-group-text {
            border-right: 0px;
            border-radius: 0px;
            border-bottom-left-radius: 6px;
            border-top-left-radius: 6px;
            padding: 0px 0px 0px 4px;
            height: 100%;
            .logo-container {
              width: 24px;
              img {
                width: 24px;
                height: 24px;
              }
            }
          }
        }
        input {
          border-left: none;
          background-color: #e9ecef;
          padding-left: initial;
          height: 30px;
          font-size: 12px;
          &:focus {
            box-shadow: initial;
            border-color: transparent;

            border: 1px solid #ced4da;
            border-left: none;
          }
        }
      }
    }
    .profile-container {
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      &:hover {
        .option {
          visibility: visible;
          opacity: 1;
        }
      }
      .profile-image {
        width: 30px;
        img {
          width: 30px;
          height: 30px;
        }
      }
      .option {
        list-style: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: white;
        box-shadow: 5px 5px 20px rgb(0 0 0 / 10%);
        padding: 10px;
        z-index: 1;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease-in-out;
      }
      .profile-text {
        p {
          font-size: 10px;
        }
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    .flag-container {
      width: 25px;
      margin-left: 20px;
      margin-right: 10px;
      img {
        width: 25px;
        height: 25px;
      }
    }
  }
  .hamburger {
    position: absolute;
    top: 62px;
    left: 10px;
  }
}
