// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.btn-primary {
  background-color: #0b5099;
  border: transparent;
  color: #fff;
  font-size: 13px;
  width: 110px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}
.btn-primary:hover {
  background-color: #08407b;
}
.btn-primary:active {
  background-color: #042141;
}
.btn-success {
  background-color: #068513;
  border: transparent;
  color: #fff;
  font-size: 13px;
  width: 110px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all ease-in-out;
}
.btn-success:hover {
  background-color: #065823;
}
.btn-success:active {
  background-color: #04410c;
}
.btn-danger {
  background-color: #be1e2d;
  border: transparent;
  color: #fff;
  font-size: 13px;
  width: 110px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all ease-in-out;
  &:hover {
    background-color: #a41120;
  }
  &.green {
    background-color: #198754 !important;
    &:hover {
      background-color: #0a6c3f !important;
    }
  }
}

.btn-danger:active {
  background-color: #45080f;
}

.btn-secondary {
  background-color: #2cb3b3;
  border: transparent;
  border-radius: 0;
  color: #fff;
  font-size: 13px;
  width: 110px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all ease-in-out;
}
.btn-secondary:hover {
  background-color: #197474;
}
.btn-secondary:active {
  background-color: #0c3c3c;
}

.button-container-trail {
  display: flex;
  gap: 10px;
  button {
    width: 160px;
  }
}
.home-button {
  position: absolute;
  top: 5%;
  left: 2%;
}
.utility-btn-container {
  margin-top: 24px;
}
.btn-back {
  border-radius: 50%;
  height: 30px;
  transition: all 0.5s ease;
  width: 30px !important;
  &:hover {
    transform: scale(1.1);
  }
}
.submission {
  button {
    width: 120px !important;
  }
}

//for tooltip
.tooltip-inner {
  background-color: #0b5099;
  border: 1px solid #0b5099;
}

.tooltip {
  .tooltip-arrow {
    &::before {
      border-top-color: #0b5099 !important;
    }
  }
}
#yesSelect {
  &:hover {
    color: "green";
  }
}

#selectOptionSearch {
  width: 165px;
  .css-b62m3t-container {
    height: 30px;
    .css-1s2u09g-control {
      height: 30px;
    }
    .css-1u3wx77-control {
      display: flex;
      align-items: flex-start;
    }
    .css-tlfecz-indicatorContainer {
      padding: 0 8px;
    }
    .css-qc6sy-singleValue {
      color: #fff;
      font-size: 13px;
    }
  }
}
#genderOptionTable {
  .dropdown {
    button {
      height: 30px;
    }
    .btn:focus {
      box-shadow: none;
    }
    .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0.4rem;
      margin-top: 0.2rem;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }
    .dropdown-menu {
      position: absolute;
      inset: 0px auto auto 0px;
      border-radius: 0;
    }
  }
}
.badge {
  display: inline-block;
  padding: 0.3rem 0.5rem;
  font-size: 85%;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0;
}
.badge.badge-light-secondary {
  background-color: rgba(130, 134, 139, 0.12);
  color: #82868b !important;
}
.badge.badge-light-success {
  background-color: rgba(40, 199, 111, 0.12);
  color: #28c76f !important;
}
.badge.badge-light-warning {
  background-color: rgba(255, 159, 67, 0.12);
  color: #ff9f43 !important;
}
.badge.badge-light-danger {
  background-color: rgba(255, 67, 67, 0.12);
  color: #ff4343 !important;
}
