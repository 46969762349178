.table-check-point {
  margin-top: 25px;
  overflow-x: auto;
  width: 100%;
  display: inline-block;
  padding: 15px;
  background-color: #e5e5e5;
  img {
    // max-width: 20px;
    // width: 20px;
    // height: 20px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
  a {
    color: #0d6efd;
  }
  td,
  th {
    padding: 5px 5px;
  }
  // thead {
  //   border-bottom: 2px solid #dfe1e6;
  // }
  tbody {
    color: #0d6efd;
    tr {
      cursor: pointer;
      &:hover {
        background-color: #ddd;
      }
    }

    td {
      height: 100%;
      .image-container {
        width: 30px;
        img {
          width: 25px !important;
          height: 25px !important;
          max-width: 25px;
          border-radius: 50%;
          object-fit: cover;
          object-position: center;
        }
      }
      .third-td {
        justify-content: initial !important;
      }
      padding: 5px 5px;
    }
  }
}
.table-stretch {
  max-width: 100%;
  white-space: nowrap;
}
