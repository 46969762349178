.admin-dashboard-trekCard {
  overflow: hidden;
  .trek-card-dashboard {
    margin-top: 20px;
    position: relative;
    .option {
      // position: absolute;
      // top: -7%;
      // right: 5%;
      .dropdown-toggle {
        background-color: #0c558c;
      }
      .dropdown-menu {
        padding: 0px 0.5rem;
        min-width: 6.9rem;
        border-radius: 0;
        .dropdown-header {
          font-size: 12px;
        }
        .dropdown-item {
          font-size: 72%;
        }
      }
      // @media (max-width: 769px) {
      //   top: -2%;
      //   right: 10%;
      // }
    }
    .weather {
      text-align: center;
      margin-bottom: 15px;
      h4 {
        color: #0c558c;
      }
      img {
        height: 50px;
        width: auto;
      }
    }

    .calendar {
      display: flex;
      justify-content: center;
      margin-bottom: 15px;
      position: relative;
      img {
        height: 150px;
        width: 140px;
      }
      .calander-text {
        position: absolute;
        top: 42%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h3 {
          color: #0c558c;
          margin-bottom: 0;
        }
      }
    }
    #refresh-button {
      // position: absolute;
      // top: -7%;
      // right: 0%;
      // @media (max-width: 769px) {
      //   top: -2%;
      // }
    }
    .custome-row {
      row-gap: 24px;
      .margin-btn {
        margin-bottom: 26px;
      }
      .card-content {
        p {
          color: #575756;
          margin-top: 0;
          // font-size: 35px;
          // font-weight: 600;
        }
      }
    }
    .trek-card {
      h4 {
        font-size: 25px;
        font-weight: 700;
        color: #0c558c;
      }
      label {
        font-weight: 700;
      }
      .input-control {
        width: 100%;
        border: none;
        height: 35px;
        padding: 0.375rem 0.75rem;
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 1.5;
        border-radius: 0;
        @media (max-width: 768px) {
          margin: 10px 0;
        }
      }
      table {
        thead {
          tr {
            th {
              font-size: 13px;
              color: #727272;
            }
          }
        }
        tbody {
          tr {
            td {
              font-size: 13px;
              padding: 1rem 0.5rem;
              b {
                text-transform: uppercase;
              }
              .checkboxes {
                label {
                  font-size: 13px;
                  font-weight: 500;
                  text-transform: capitalize;
                }
              }
            }
          }
        }
      }
    }
    .role-content {
      display: flex;
      justify-content: space-between;
      p {
        font-size: 25px;
        font-weight: 700;
        color: rgb(0, 166, 81);
        margin-left: 20px;
      }
      a {
        margin-right: 25px;
      }
    }
    .table-content {
      margin-top: 40px;
      background-color: #e5e5e5;
      padding: 20px;
    }
    .table-responsive {
      .checkboxes:not(:first-child) {
        @media (max-width: 768px) {
          display: inline-grid;
        }
      }
    }
  }

  .admin-checkpoint {
    margin-top: 20px;

    .checkpoint-btn-container {
      display: flex;
      justify-content: right;

      button {
        width: 160px;
      }
      margin-bottom: 30px;
    }
    .admin-checkpoint-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      cursor: pointer;
      .card-content {
        margin-bottom: 20px;
        padding-right: 0;
      }
    }
    .coutome-row {
      row-gap: 24px;
    }
  }
}

.admin-checkPointEntryDetail {
  .lukla-detail-container {
    margin-top: 24px;

    .colored-card {
      height: 100%;
    }
  }
  .other-detail-container {
    .table-container {
      padding-top: 40px;
      .custome-row {
        justify-content: space-between;
        .colored-card {
          margin-bottom: 26px;
          height: 100px;
        }
      }
      .table-header {
        padding: 0px 20px;
      }
    }
  }
  .page-header {
    margin-bottom: 20px;
    p {
      font-size: 25px;
      font-weight: 700;
      color: #0c558c;
    }
  }
  .custome-row {
    row-gap: 26px;
  }

  .userStattusCard {
    .sm-title {
      p {
        color: #0c558c;

        font-weight: 500;
      }
    }
    .body {
      p {
        margin-top: 4px;
        font-size: 12px;
        color: black;
        span {
          font-weight: 500;
        }
      }
      ul li::marker {
        color: green;
      }
    }
  }
  .table-header {
    // margin-bottom: 20px;
    padding: 20px 0px 0px 20px;
    p {
      font-size: 25px;
      font-weight: 700;
      color: rgb(0, 166, 81);
    }
  }
  .table-container {
    background-color: #e5e5e5;
    margin-top: 26px;
    .table-content {
      padding: 15px;

      table {
        tr {
          td {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.admin-checkPointCreateUser {
  margin-top: 70px;
  background-color: #e5e5e5;
  padding: 20px;
  .checkpoint-form {
    .input-group {
      margin-bottom: 10px;
      label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        column-gap: 10px;
        flex-wrap: wrap;
        position: relative;
        @media (max-width: 1440px) {
          display: grid;
          justify-content: inherit;
        }
        input {
          width: 66%;
          border: none;
          border-radius: initial;
          @media (max-width: 1440px) {
            width: 100%;
            margin-top: 10px;
          }
        }
        &.select-label {
          display: flex;
          justify-content: initial;
          column-gap: 105px;
          @media (max-width: 1440px) {
            display: grid;
          }
          select {
            border: none;
            border-radius: initial;
            justify-self: flex-start;
            height: 28.5px;
            width: 180px;
            padding: 0 5px;
            @media (max-width: 1440px) {
              width: 100%;
              margin-top: 10px;
            }
          }
        }
        &.second {
          column-gap: 117px;
        }
      }
      p {
        width: 66%;
        margin-left: auto;
        @media (max-width: 1440px) {
          width: 100%;
        }
      }
      .image-file {
        justify-content: initial;
        align-items: initial;
        cursor: pointer;
        &.hide {
          &::after {
            opacity: 0;
          }
        }
        &::after {
          content: "Insert Image";
          // width: 100px;
          flex-grow: 1;
          // height: 200px;
          border: 1px solid;
          background-color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          aspect-ratio: 1;
        }
        &.error {
          &::after {
            border: 1px solid #a41120;
          }
        }
        input {
          display: none;
        }
        .file-image {
          position: absolute;
          // left: 55px;
          // width: calc(100% - 55px);
          width: 100%;
          aspect-ratio: 1;
          display: none;
          img {
            object-fit: contain;
            object-position: center;
            height: 100%;
          }
          &.show {
            display: block;
          }
        }
      }
    }
  }
}

.admin-user {
  // margin-top: 50px;

  .button-container {
    display: flex;
    padding: 0;
    margin-right: 0;
    p {
      font-size: 25px;
      font-weight: 700;
      color: #0c558c;
    }
    button {
      margin-left: auto;
    }
  }
  .table-content {
    margin-top: 40px;
    padding: 20px;
    background-color: #e5e5e5;
    .search-container {
      margin-bottom: 20px;
    }
    td {
      svg {
        cursor: pointer;
      }
      svg:focus {
        outline: none;
      }
    }
  }
}
.settings {
  .admin-checkPointCreateUser {
    background-color: #fff;
    .form-control {
      background-color: #e5e5e5;
    }
  }
}
.change_password_container {
  div {
    position: absolute;
    right: 0;
    padding: 7px;
    @media (max-width: 1440px) {
      bottom: 0;
    }
    svg {
      height: 20px;
      width: 20px;
    }
  }
}
