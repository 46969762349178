.pagination {
  display: flex;
  align-items: center;
  transition: 0.3s all ease-in-out;
  .page-link {
    background-color: transparent;
    border: none;
    color: #999;
  }
  .page-item {
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 3px;
    .page-link:focus {
      z-index: 3;
      background-color: none;
      outline: 0;
      box-shadow: none;
    }
  }
  .active {
    background-color: #0c558c;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    .page-link {
      color: #fff;
    }
  }
  .disabled {
    opacity: 0.5;
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0c558ced;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0c558c;
}
