.adminViewDetail {
  margin-top: 70px;
  .password-image-container {
    margin-bottom: 24px;
  }
  .info-card-coponent {
    margin-bottom: 24px;
  }
  .card {
    width: 100%;
    height: auto;
    border: none;
    border-radius: 0;
    position: relative;
    padding: 15px;
    margin-bottom: 1.5rem;
  }
  .card-details {
    align-items: center;
    padding: 5px 8px;
    label {
      font-size: 13px;
      font-weight: 500;
    }
    p {
      font-size: 14px;
      font-weight: 700;
    }
  }
  .image-container {
    min-height: 165px;
    min-width: 165px;
    max-height: 165px;
    max-width: 165px;
    padding: 0 5px;
    margin: 0 0 15px 0;
    text-align: center;
    img {
      aspect-ratio: 1;
    }
  }
}
.nfc {
  .active {
    background-color: #0fa106;
  }
}
