.info-container {
  .container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .info-content {
      max-width: 350px;
      .header-top {
        padding-right: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        h4 {
          font-size: 22px;
          font-weight: 700;
          margin: initial;
          line-height: 1;
        }
        .title-one {
          color: #003d68;
          font-weight: 700;
        }
        .title-two {
          color: #dc506d;
        }
      }
      .text-content {
        text-align: center;
        margin-bottom: 15px;

        .logo {
          margin-inline: auto;
          margin-bottom: 10px;
          max-width: 100px;
        }
        .text-container {
          p {
            color: #2e4068;
            font-weight: 700;
            font-size: 25px;
          }
        }
      }
      .info-content-list {
        margin-top: 30px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .list-item {
          width: 100%;
          margin-bottom: 20px;
          border-radius: 8px;
          overflow: hidden;
          transition: all 0.3s ease-in-out;
          text-align: center;
          background-color: #003d68;
          padding: 16px 0px;
          cursor: pointer;
          &:hover {
            transform: scale(1.1);
            &:active {
              transform: scale(0.9);
            }
            // p {
            //   color: red;
            // }
          }
          p {
            color: white;
            font-weight: 700;
            font-size: 20px;
            line-height: 1;
            font-size: 16px;
          }
        }
      }
    }
    .button-container {
      display: flex;
      justify-content: space-between;
      .btn1,
      .btn2 {
        width: 47%;
        button {
          padding: 20px 0px;
          width: 100%;
          border-radius: 8px;
        }
      }
      .btn1 {
        .btn-primary {
          background-color: #2db3ab;
        }
      }
      .btn2 {
        .btn-primary {
          background-color: #da4261;
        }
      }
    }
    .footer-text {
      margin-top: 10px;
      span {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #7f918f;
        p {
          text-align: center;
          color: #3d889b;
          font-weight: 600;
        }
      }
    }
  }
}

.info-about {
  width: 100%;
  min-height: 100%;
  margin: auto;
  padding: 40px 0;
  h3 {
    margin-bottom: 40px;
  }
  p {
    font-size: 18px;
    font-weight: 500;
    text-align: justify;
    margin-bottom: 20px;
  }
}

@keyframes colorChangeAnimation {
  0% {
    color: red;
  }
  15% {
    color: yellow;
  }
  40% {
    color: blue;
  }
  55% {
    color: green;
  }
  70% {
    color: grey;
  }
  100% {
    color: white;
  }
}
.info-detail-color-change {
  h2 {
    transition: all 3s ease;
    animation-iteration-count: infinite;
    animation-name: colorChangeAnimation;
    animation-duration: 1s;
    color: white;
  }
}
