.sidebar {
  display: block;
  position: absolute;
  top: 70px;
  bottom: 0;
  width: 100%;
  max-width: 210px;
  padding: 20px;
  overflow-y: auto;
  background-color: #0c558c;
  min-height: calc(100vh - 100px);

  @media (max-width: 991px) {
    width: calc(100% - 40px);
    max-width: calc(100% - 40px);
    top: 104px;
    z-index: 1;
    right: 100%;
    transition: right 0.5s ease;
    &.showSidebar {
      right: 20px;
    }
    .box-header {
      h2 {
        text-align: center;
      }
    }
    .box-content {
      display: flex;
      justify-content: center;
      ul {
        display: inline-flex;
      }
    }
  }
  .box-header {
    margin-bottom: 30px;
    border-bottom: 1px solid white;
    h2 {
      color: white;
      font-size: 20px;
      font-weight: 400;
      span {
        font-weight: 700;
      }
    }
  }
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  .nav-link {
    display: flex;
    align-items: center;
    column-gap: 8px;
    &:hover,
    &.active {
      text-decoration: none;
      .icon-container {
        img {
        }
      }
      .text-container {
        p {
          font-size: 12x;
          color: #f8b040;
        }
      }
    }
    .icon-container {
      max-width: 25px;
      img {
        width: 25px;
        height: 25px;
      }
    }
    .text-container {
      color: white;
      p {
        font-size: 12px;
      }
    }
  }
}
